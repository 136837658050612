import { post } from "./base_descarga";

const API_SERVICE_REPORTE_CSV = "/api/sivi_data/V1/descargar_reporteCSV";
const API_SERVICE_REPORTEV2 = "/api/sivi_data/V1/descargar_reporteV2";
const API_SERVICE_REPORTEV2_PACIENTE = "/api/sivi_data/V1/ReporteV2Paciente";
const API_SERVICE_REPORTEV2_PACIENTE_GESTANTE =
  "/api/sivi_data/V1/descargar_reporte_padron_gestantes";
const API_SERVICE_REPORTE_INDICADOR = "/api/sivi_data/V1/reporteIndicador";
const API_SERVICE_REPORTEV2_COLUMNS = "/api/sivi_data/V1/descargar_reporteV2Column";

const API_SERVICE_REPORTE_PACIENTE_PROVINCIA = "/api/sivi_data/V1/descargar_reporte_padron";
const API_SERVICE_REPORTE_PACIENTE_PROVINCIA_GESTANTE =
  "/api/sivi_data/V1/descargar_reporte_padron_gestantes";
const API_SERVICE_REPORTE_PACIENTE_PACIENTE = "/api/sivi_data/V1/descargar_reporte_paciente";

const API_SERVICE_ACCIONES = "/api/sivi_data/V1/acciones";

const API_SERVICE_PUBLICO_REPORTEV2 = "/api/Prueba/V1/descargar_reporteV2";
const API_SERVICE_PUBLICO_PACIENTE = "/api/Prueba/V1/descargar_reporte_paciente";

const API_SERVICE_PUBLICO_DOWNLOAD_CSV = "/api/Prueba/downloadCSV";

export default {
  getReporte: (payload, idindicador, sigla) => {
    var $tabla = `sivi_data.${sigla}`;

    const { filtro, distrito, search } = payload;

    const request = {
      _type: 1,
      _table: sigla,
      _petitions: [
        {
          name: "_script",
          value: "web_data_detalle_indicador_exportar",
          type: "string"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        },
        {
          name: "@filtros_AND",
          value: "distrito",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${distrito}%`,
          type: "string"
        }
      ]
    };

    if (payload.filtro !== "all") {
      request._petitions.push({
        name: "@filtros_AND",
        value: "numerador|distrito",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${filtro}|%${distrito}%`,
        type: "string"
      });
    }

    if (search !== "") {
      request._petitions.push({
        name: "@filtros_OR",
        value: "documento|concat(apellidopaterno, ' ', apellidomateno , ' , ' , nombre)",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%`,
        type: "string"
      });
    }

    return post(API_SERVICE_REPORTE_CSV, request);
  },

  getReporteIndicador01: (distrito, anio, provincia, _table, _titulo) => {
    const request = {
      _type: 1,
      _table: _table,
      _title: _titulo,
      _fuente: "PADRÓN NOMINAL, HISMINSA y CNV",
      _petitions: [
        {
          name: "_script",
          value: "sp_web_query_obtenersienanemia_public",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value: "DISTRITO|NUMERADOR|DENOMINADOR|RESULTADO",
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_REPORTEV2, request);
  },

  getReporteIndicadorfin2: (distrito, idperiodo, provincia, _table, _titulo) => {
    const request = {
      _type: 1,
      _table: _table,
      _title: _titulo,
      _fuente: "PADRÓN NOMINAL, HISMINSA y CNV",
      _petitions: [
        {
          name: "_script",
          value: "web_data_detalle_ind_registro_fin2",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value:
            "MICRORED|ID_ESTABLECIMIENTO|ESTABLECIMIENTO|LATITUD|LONGITUD|NUMERADOR_DISTRITO|DENOMINADOR_DISTRITO|NUMERADOR_ESTABLECIMIENTO|DENOMINADOR_ESTABLECIMIENTO",
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${idperiodo}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_REPORTEV2, request);
  },

  ReporteDetalleIndicador: (payload, idindicador, sigla, _table, _titulo, periodo) => {
    var { filter, search, distrito, provincia, establecimiento, periodo_inicio, periodo_fin } =
      payload;

    if (periodo_fin == "") {
      var dd = new Date();

      var anio = dd.getFullYear();
      var mes = dd.getMonth() < 10 ? "0" + dd.getMonth() : dd.getMonth();

      periodo_fin = anio + "" + mes;
    }

    if (periodo_inicio == "") {
      periodo_inicio = "201201";
    }

    var $tabla = `sivi_data.${sigla}`;

    const request = {
      _type: 1,
      _table: _table,
      _title: _titulo,
      _petitions: [
        {
          name: "_script",
          value: "web_reporte_detalle_indicador",
          type: "int"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        },
        {
          name: "@PERIODO_INI",
          value: `${periodo_inicio}`,
          type: "string"
        },
        {
          name: "@PERIODO_FIN",
          value: `${periodo_fin}`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value:
            "documento|concat(apellidopaterno, ' ', apellidomateno , ' , ' , nombre)|concat(apellidopaterno, ' ', apellidomateno , ' ' , nombre)",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    if (establecimiento == "") {
      request._petitions.push({
        name: "@filtros_AND",
        value: "numerador|distrito|provincia|i.idestablecimiento",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `%${filter}%|%${distrito}%|%${provincia}%|%${establecimiento}%`,
        type: "string"
      });
    } else if (establecimiento == -1) {
      request._petitions.push({
        name: "@filtros_AND",
        value: "numerador|distrito|provincia|i.red",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `%${filter}%|%${distrito}%|%${provincia}%|%TACNA%`,
        type: "string"
      });
    } else if (establecimiento == 0) {
      request._petitions.push({
        name: "@filtros_AND",
        value: "numerador|distrito|provincia|i.red",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `%${filter}%|%${distrito}%|%${provincia}%|%NO PERTENECE A NINGUNA RED%`,
        type: "string"
      });
    } else if (establecimiento == -2) {
      request._petitions.push({
        name: "@filtros_AND",
        value: "numerador|distrito|provincia",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `%${filter}%|%${distrito}%|%${provincia}%`,
        type: "string"
      });
    } else {
      request._petitions.push({
        name: "@filtros_AND",
        value: "numerador|distrito|provincia|i.idestablecimiento",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `%${filter}%|%${distrito}%|%${provincia}%|%${establecimiento}%`,
        type: "string"
      });
    }

    return post(API_SERVICE_REPORTEV2_COLUMNS, request);
  },

  getReporteIndicador: (payload, _table, _titulo) => {
    const request = {
      _type: 1,
      _table: _table,
      _title: _titulo,
      _fuente: "PADRÓN NOMINAL, HISMINSA y CNV",
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_indicador_reporte",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value:
            "ID_INDICADOR|DENOMINACIÓN|TÍTULO_REPORTE|TÍTULO_NUMERADOR|TÍTULO_DENOMINADOR|NOMBRE_TABLA|ABREVIATURA|ACTIVO|ID_GRUPO|ID_TIPO_FICHA_TECNICA|NOMBRE_GRUPO",
          type: "string"
        }
      ]
    };

    if (payload?.filter && payload.filter !== "all") {
      const { filter, search } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "i.activo",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${filter}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value:
          "i.denominacion|i.tituloreporte|i.titulodenominado|i.titulonumerado|i.abreviatura|g.denominacion",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%|%${search}%|%${search}%|%${search}%|%${search}%`,
        type: "string"
      });
    } else if (payload?.search) {
      const { search } = payload;

      request._petitions.push({
        name: "@filtros_OR",
        value:
          "i.denominacion|i.tituloreporte|i.titulodenominado|i.titulonumerado|i.abreviatura|g.denominacion",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%|%${search}%|%${search}%|%${search}%|%${search}%`,
        type: "string"
      });
    }

    return post(API_SERVICE_REPORTEV2, request);
  },

  getReporteIndicadorv2Indicador: (_sigla, _table, _titulo, _numerador, _denominador, _porcentaje, provincia, distrito,periodo, quintil = "") => {
    var $tabla = `sivi_data.` + _sigla;
    
    const request = {
      _type: 1,
      _table: _table,
      _title: _titulo,
      numerador: _numerador,
      denominador: _denominador,
      porcentaje: _porcentaje,
      _fuente: "PADRÓN NOMINAL, HISMINSA y CNV",
      _petitions: [
        {
          name: "_script",
          value: (quintil == "") ?  "web_data_detalle_indicador_reporte_V2" : 
                                    "web_data_detalle_indicador_reporte_V2_quintil",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value:
            "IDPERIODO|RENAES|DOCUMENTO|NOMBRE|NUMERADOR|FECHA_NACIMIENTO|DISTRITO|MICRORED|ESTABLECIMIENTO",
          type: "string"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%${periodo}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: `%${quintil}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_REPORTE_INDICADOR, request);
  },

  getReporteIndicadorV2: (_sigla, _table, _titulo, _numerador, _denominador, _porcentaje) => {
    var $tabla = `sivi_data.` + _sigla;

    const request = {
      _type: 1,
      _table: _table,
      _title: _titulo,
      numerador: _numerador,
      denominador: _denominador,
      porcentaje: _porcentaje,
      _fuente: "PADRÓN NOMINAL, HISMINSA y CNV",
      _petitions: [
        {
          name: "_script",
          value: "web_data_detalle_indicador_reporte",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value:
            "IDPERIODO|RENAES|DOCUMENTO|NOMBRE|NUMERADOR|FECHA_NACIMIENTO|DISTRITO|MICRORED|ESTABLECIMIENTO",
          type: "string"
        },
        {
          name: "@TABLE",
          value: $tabla,
          type: "int"
        },
        {
          name: "@DISTRITO",
          value: `%%`,
          type: "string"
        },
        {
          name: "@IDPERIODO",
          value: `%%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_REPORTE_INDICADOR, request);
  },

  getReportePacienteProvinciaV2: (filtro = "", fecha_corte, dia_inicio, dia_final, titulo = "") => {
    const request = {
      _type: 1,
      _table: "paciente_provincia",
      _valor: "PROVINCIA",
      _title: titulo,
      _filtro: filtro,
      _fuente: "Padrón Nominal",
      _petitions: [
        {
          name: "_script",
          value:
            dia_inicio == 0
              ? "web_data_reporte_padron_provincia_reporte"
              : "web_data_reporte_padron_provincia_reporte_v2",
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_DOWNLOAD_CSV, request);
  },

  getReportePacienteProvincia: (filtro = "", fecha_corte, dia_inicio, dia_final) => {
    const request = {
      _type: 1,
      _table: "paciente_provincia",
      _valor: "PROVINCIA",
      _filtro: filtro,
      _petitions: [
        {
          name: "_script",
          value:
            dia_inicio == 0
              ? "web_data_reporte_padron_provincia_reporte"
              : "web_data_reporte_padron_provincia_reporte_v2",
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_PACIENTE, request);
  },

  getReportePacienteProvinciaGestante: (filtro = "", fecha_inicio, fecha_fin) => {
    const request = {
      _type: 1,
      _table: "paciente_provincia",
      _valor: "PROVINCIA",
      _filtro: filtro,
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron_provincia_gestantes_v2",
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_INICIO",
          value: `${fecha_inicio}`,
          type: "string"
        },
        {
          name: "@FECHA_FIN",
          value: `${fecha_fin}`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_PACIENTE, request);
  },

  getReportePacienteDistrito: (provincia, filtro = "", fecha_corte, dia_inicio, dia_final) => {
    const request = {
      _type: 1,
      _table: "paciente_distrito",
      _valor: "DISTRITO",
      _filtro: filtro,
      _petitions: [
        {
          name: "_script",
          value:
            dia_inicio == 0
              ? "web_data_reporte_padron_distrito_reporte"
              : "web_data_reporte_padron_distrito_reporte_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_PACIENTE, request);
  },

  getReportePacienteDistritoGestante: (provincia, filtro = "", fecha_inicio, fecha_fin) => {
    const request = {
      _type: 1,
      _table: "paciente_distrito",
      _valor: "DISTRITO",
      _filtro: filtro,
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron_distrito_gestante_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_INICIO",
          value: `${fecha_inicio}`,
          type: "string"
        },
        {
          name: "@FECHA_FIN",
          value: `${fecha_fin}`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_PACIENTE, request);
  },

  getReportePacienteCentroPoblado: (
    provincia,
    distrito,
    filtro = "",
    fecha_corte,
    dia_inicio,
    dia_final
  ) => {
    const request = {
      _type: 1,
      _table: "paciente_centro_poblado",
      _valor: "CENTRO POBLADO",
      _filtro: filtro,
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron_centro_poblado_reporte",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_PACIENTE, request);
  },

  getReportePacienteCentroPobladoGestante: (provincia, distrito, filtro = "") => {
    const request = {
      _type: 1,
      _table: "paciente_centro_poblado",
      _valor: "CENTRO POBLADO",
      _filtro: filtro,
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron_centro_poblado_gestante_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_PACIENTE, request);
  },

  getReporteEstablecimientoo: (
    provincia,
    distrito,
    filtro = "",
    fecha_corte,
    dia_inicio,
    dia_final,
    fecha_inicio,
    fecha_fin
  ) => {
    const request = {
      _type: 1,
      _table: "paciente_establecimiento",
      _valor: "ESTABLECIMIENTO",
      _filtro: filtro,
      _petitions: [
        {
          name: "_script",
          value:
            dia_inicio == 0
              ? "web_data_reporte_padron_establecimiento_reporte"
              : "web_data_reporte_padron_establecimiento_reporte_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_CORTE",
          value: fecha_corte,
          type: "string"
        },
        {
          name: "@DIA_INICIO",
          value: dia_inicio,
          type: "int"
        },
        {
          name: "@DIA_FINAL",
          value: dia_final,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_PACIENTE, request);
  },

  getReporteEstablecimientooGestante: (
    provincia,
    distrito,
    filtro = "",
    fecha_inicio,
    fecha_fin
  ) => {
    const request = {
      _type: 1,
      _table: "paciente_establecimiento",
      _valor: "ESTABLECIMIENTO",
      _filtro: filtro,
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron_establecimiento_gestante_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@FILTRO",
          value: `%${filtro}%`,
          type: "string"
        },
        {
          name: "@FECHA_INICIO",
          value: `${fecha_inicio}`,
          type: "string"
        },
        {
          name: "@FECHA_FIN",
          value: `${fecha_fin}`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_PUBLICO_PACIENTE, request);
  },

  getReporteErrorLog: (fecha_ini, fecha_fin) => {
    const request = {
      _type: 1,
      _table: "error_log_" + fecha_ini + "/" + fecha_fin,
      _fuente: "SIVINUR",
      _title: "REPORTE DE ACCESOS DE ERRORES DEL SISTEMA",
      _petitions: [
        {
          name: "_script",
          value: "web_segu_error_log",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value:
            "ID_ERROR_LOG|MÓDULO|ACCIÓN|ERROR|USUARIO_INGRESO|USUARIO_ACTUALIZÓ|FECHA_INGRESO|FECHA_ACTUALIZÓ",
          type: "string"
        },
        {
          name: "@FECHANI",
          value: fecha_ini,
          type: "string"
        },
        {
          name: "@FECHAFIN",
          value: fecha_fin,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_REPORTEV2, request);
  },

  getReporteUsuarioLog: (fecha_ini, fecha_fin, usuario) => {
    const request = {
      _type: 1,
      _table: "user_log_" + usuario,
      _fuente: "SIVINUR",
      _title: "REPORTE DE ACCESOS DE USUARIO",
      _petitions: [
        {
          name: "_script",
          value: "web_data_user_log",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value: "ID_REGISTRO|USUARIO|MÓDULO|OPCIÓN|IP_PÚBLICA|ESTABLECIMIENTO|FECHA_REGISTRO",
          type: "string"
        },
        {
          name: "@FECHANI",
          value: fecha_ini,
          type: "string"
        },
        {
          name: "@FECHAFIN",
          value: fecha_fin,
          type: "string"
        },
        {
          name: "@USUARIO",
          value: usuario,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_REPORTEV2, request);
  },

  getEstablecimientos: (payload) => {
    const { provincia, distrito, search } = payload;

    const request = {
      _type: 1,
      _fuente: "HISMINSA",
      _table: "establecimientos",
      _title: "REPORTE DE ESTABLECIMIENTOS",
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_select_establecimientos",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value:
            "TELÉFONO|LATITUD|LONGITUD|CODIGO_RENAES|NOMBRE_ESTABLECIMIENTO|DIRECCIÓN|SECTOR|ID_UBIGEO|DISA|DISTRITO|RED|MICRORED",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "provincia|distrito|sector",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${provincia}%|%${distrito}%|%GOBIERNO REGIONAL%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "denominacion|microred",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_REPORTEV2, request);
  },

  getAllNinios: (payload) => {
    const { provincia, distrito, search } = payload;

    const request = {
      _type: 1,
      _table: "reg_paciente_ninios",
      _fuente: "PADRÓN NOMINAL, HISMINSA y CNV",
      _title: "REPORTE PACIENTES NIÑOS",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_paciente",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value:
            "DOCUMENTO|NOMBRES_COMPLETOS|F. NACIMIENTO|SEXO|DIRECCION|DIRECCION2|CELULAR|CELULAR2|ESTABLECIMIENTO|CODIGO_RENAES",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "u.provincia|u.distrito|idtipopaciente",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${provincia}%|%${distrito}%|%1%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "CONCAT(apellidopaterno, ' ', apellidomaterno, ' ' , nombre)|documento",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_REPORTEV2_PACIENTE, request);
  },

  getAllGestantes: (payload) => {
    const { provincia, distrito, search } = payload;

    const request = {
      _type: 1,
      _table: "reg_paciente_gestantes",
      _fuente: "PADRÓN NOMINAL, HISMINSA y CNV",
      _title: "REPORTE DE PACIENTES GESTANTES",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_paciente",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value:
            "DOCUMENTO|NOMBRES_COMPLETOS|DIRECCION|DIRECCION2|CELULAR|CELULAR2|EMAIL|ESTABLECIMIENTO|CODIGO_RENAES",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "u.provincia|u.distrito|idtipopaciente",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${provincia}%|%${distrito}%|%2%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "CONCAT(apellidopaterno, ' ', apellidomaterno, ' ' , nombre)|documento",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_REPORTEV2_PACIENTE_GESTANTE, request);
  },

  getAllProvinciaNutricion: (anio) => {
    const request = {
      _type: 1,
      _table: "reporte_provincia_anemia",
      _title: "NIÑOS CON ANEMIA DE 6 A 36 MESES DE EDAD CON ANEMIA POR PROVINCIA",
      _valor: "PROVINCIA",
      _petitions: [
        {
          name: "_script",
          // value: "web_data_reporte_nutricional_provincia_v2",
          value: "web_seg_select_anemia_v2_provincia",
          type: "string"
        },
        {
          name: "@ANIO",
          value: anio,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_REPORTE_PACIENTE_PROVINCIA, request);
  },

  getAllDistritoNutricion: (provincia, anio) => {
    const request = {
      _type: 1,
      _table: "reporte_distrito_anemia",
      _title: "NIÑOS CON ANEMIA DE 6 A 36 MESES DE EDAD CON ANEMIA POR DISTRITO",
      _valor: "DISTRITO",
      _petitions: [
        {
          name: "_script",
          // value: "web_data_reporte_nutricional_distrito_V2",
          value: "web_seg_select_anemia_v2_distrito",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: anio,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_REPORTE_PACIENTE_PROVINCIA, request);
  },

  getAllEstablecimientoNutricion: (provincia, distrito, anio) => {
    const request = {
      _type: 1,
      _table: "reporte_destablecimiento_anemia",
      _title: "NIÑOS CON ANEMIA DE 6 A 36 MESES DE EDAD CON ANEMIA POR ESTABLECIMIENTOS",
      _valor: "ESTABLECIMIENTO",
      _petitions: [
        {
          name: "_script",
          // value: "web_data_reporte_nutricional_eess_v2",
          value: "web_seg_select_anemia_v2_establecimiento",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: anio,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_REPORTE_PACIENTE_PROVINCIA, request);
  },

  getReportePadronNominal: (payload, _table, _titulo) => {
    const request = {
      _type: 1,
      _table: _table,
      _title: _titulo,
      _fuente: "PADRÓN NOMINAL",
      _petitions: [
        {
          name: "_script",
          value: "web_migr_select_padron_nominal",
          type: "string"
        },
        {
          name: "@COLUMNS",
          value: "DNI|NOMBRE|SEXO|CNV|F_NACIMIENTO|ESTABLECIMIENTO|DISTRITO",
          type: "string"
        }
      ]
    };

    if (payload?.establecimiento && payload.establecimiento !== "all") {
      const { search, establecimiento } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "nombre_eess_atencion",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${establecimiento}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value: "CONCAT(apep_pat, ' ' , apep_mat, ' ' , nombrr)|dni|cnv",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%|%${search}%`,
        type: "string"
      });
    } else if (payload.distrito !== "all") {
      const { distrito } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "distrito",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${distrito}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value: "CONCAT(apep_pat, ' ' , apep_mat, ' ' , nombrr)|dni|cnv",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${payload.search}%|%${payload.search}%|%${payload.search}%`,
        type: "string"
      });
    } else if (payload?.search) {
      const { search } = payload;

      request._petitions.push({
        name: "@filtros_OR",
        value: "CONCAT(apep_pat, ' ' , apep_mat, ' ' , nombrr)|dni|cnv",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%|%${search}%`,
        type: "string"
      });
    }

    console.log("getReportePadronNominal", request);

    return post(API_SERVICE_REPORTEV2, request);
    // return post(API_SERVICE_QUERY, request);
  },

  getReporteAcciones: (acciones, actividades) => {
    const request = {
      _type: 1,
      _table: "atenciones_paciente",
      _title: "ATENCIONES DEL PACIENTE",
      _fuente: "PADRÓN NOMINAL, HISMINSA y CNV",
      _petitions: [
        {
          name: "acciones",
          value: JSON.stringify(acciones),
          type: "array"
        },
        {
          name: "actividades",
          value: JSON.stringify(actividades),
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_ACCIONES, request);
  }
};
